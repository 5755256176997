import React, { useState } from "react";

import './Selector.scss'

const Selector = ({selectorData, name, userFormData, setUserFormData, placeholder}) => {

	const [isSelector, setIsSelector] = useState(false)
	const [isHide, setIsHide] = useState(false) 

	const handleFormChange = (e, value) => {
    setUserFormData({
      ...userFormData,
      [e.target.id]: value,
    });

		setIsSelector(false)
		setIsHide(false)
  };

	const functionSelecotClick = () => {
		setIsSelector(!isSelector)
		setIsHide(true)
	}

  return (
    <div>
        <div 
					className="DataDefault" 
					id={name}
					onClick={functionSelecotClick} 
					onChange={(e) => handleFormChange(e)}
					style={{color: userFormData[name] === "" ? "rgba(0,0,0, .4)" : '#000'}}
				>{userFormData[name] === "" ? placeholder : userFormData[name]}</div>
				{isSelector ?
				<>
					<div className="wrapperListSelector">
						{selectorData.map((item, index) => {
							return <div 
								id={name}
								onClick={(e) => handleFormChange(e, item)} 
								key={index}
								className="wrapperListSelectorItem"
							>{item}</div>
						})}
					</div> 
				</> : null	
				}
				{isHide ?
					<div className="screenHide" onClick={() => {setIsHide(false); setIsSelector(false)}}></div> : null
				}
    </div>
  );
};

export default Selector;
