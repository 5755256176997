import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

import Selector from "../../UI/Selector/Selector";

import './Registration.scss'

const Registration = () => {
  const today = new Date();
  const year = today.getFullYear() - 14;

  const [popUpStatus, setPopUpStatus] = useState(false);
  
  const [userFormData, setUserFormData] = useState({
    gender: "",
    sphera: "",
    educationLevel: "",
    mlevel: "",
    birthYear: "",
  });
  const [isFormFilled, setIsFormFilled] = useState(false);

  const sphere = [
    "Без акцентов на специфику.",
    "Безопасность и правоохранение.",
    "Бизнес и предпринимательство.",
    "Информационные технологии.",
    "Медицина и здравоохранение.",
    "Менеджмент и управление.",
    "Педагогика и воспитание.",
    "Образование и наука.",
    "Промышленность и индустрия.",
    "Связь и коммуникации.",
    "Строительство и транспорт.",
    "Торговля и сервисы.",
    "Финансовые инструменты.",
    "Сельское хозяйство.",
  ]

  const educationLevel = [
    "Без образования",
    "Высшее образование — специалитет, магистратура",
    "Высшее образование — бакалавриат",
    "Два и более высших образования",
    "Среднее общее образование",
    "Ученая степень",
    "Среднее профессиональное образование",
  ]

  const mlevel = [
    "Достаток. Не испытываю затруднений, помогаю материально другим",
    "Бедность. Денег не хватает на продукты питания, лекарства, одежду",
    "Нуждаюсь. Денег хватает лишь на основные продукты питания и одежду",
    "Нищета. Живу в крайней нужде",
    "Стабильное. Особых материальных затруднений нет, но не все покупки по карману",
    "Устойчивое. Нет никаких материальных затруднений",
  ]

  useEffect(() => {
    const isFilled = Object.values(userFormData).every((value) => value !== "");
    setIsFormFilled(isFilled);
  }, [userFormData]);

  return (
    <div className="WrapperCenterAnket">

      
      <div className="WrapperAnket">
        <h3>Анкетные данные пользователя</h3>
          <div className="titleAnket">
            Пол
          </div>
          <Selector
            name="gender"
            placeholder="Пол"
            selectorData={['М', 'Ж']}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <div className="titleAnket">
            Год рождения
          </div>
          <Selector
            name="birthYear"
            placeholder="Год рождения"
            selectorData={Array.from({ length: 86 }, (_, i) => year - i)}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <div className="titleAnket">
            Cфера деятельности *
          </div>
          <Selector
            name="sphera"
            placeholder=" Cфера деятельности * "
            selectorData={sphere}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <div className="titleAnket">
            Уровень образования *
          </div>
          <Selector
            name="educationLevel"
            placeholder="Уровень образования *"
            selectorData={educationLevel}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <div className="titleAnket">
            Материальное положение
          </div>
          <Selector
            name="mlevel"
            placeholder="Материальное положение"
            selectorData={mlevel}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
          <div className="AnketLine">
            * Просьба указать информацию о главе семьи.
          </div>
        </div>
      {popUpStatus && (
        <div className="popUp">
          <div className="popUpRegistration">
            <div className="popUpRegistrationContent">
              <div>
                Представьте себе <b>идеальную</b> жизнь.
              </div>
              <div>
                Ту, что приносит максимальное счастье и полную
                удовлетворённость.{" "}
              </div>
              <div>
                Какая она? <b>Организуйте</b> себе свою мечту.
              </div>
              <div>
                У вас есть 100 единиц уникального универсального <b>ресурса</b>,
                который может буквально всё.
              </div>
              <div>
                Больше нет; но что особенно важно - и <b>не будет</b>.
              </div>
              <div>
                <b>Распределите</b> этот ресурс по ячейкам так, как считаете
                нужным. Сколько куда-то будет вложено, <b>настолько</b> это и
                будет организовано.
              </div>
              <div>
                Помните - Вы <b>инвестируете</b> в то, без чего ваша жизнь не
                будет качественной.
              </div>
              <div>
                11 групп, в каждой по 4 качества и дополнительное поле для
                прочего. Если чего-то важного для вас нет в ячейках,{" "}
                <b>оставьте </b>
                ресурс незадействованным.
              </div>
              <div>
                Никаких <b>ограничений</b> - всё так, как Вам понимается,
                хочется или кажется.
              </div>
            </div>
            <Link 
              className="btnActive" 
              to={'TokenData'}
              state={{ from: userFormData }}
            >Начать</Link>
          </div>
        </div>
      )}
      <div className="wrapperBtn">
        <button
        className={isFormFilled ? 'btnActive btnActiveBig': 'btnRegister btnActiveBig'}
          disabled={isFormFilled ? false : true}
          onClick={() => setPopUpStatus(true)}
        >
          Далее
        </button>
      </div>
    </div>
  );
};

export default Registration