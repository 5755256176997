import React from "react";
import { Route, Routes, HashRouter  } from 'react-router-dom';

import TokenData from "./Pages/TokenData/TokenData";
import Registration from "./Pages/Registration/Registration";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Registration />}/>
        <Route exact path="/TokenData" element={<TokenData />}/>        
      </Routes>
    </HashRouter>
  );
};

export default App;
